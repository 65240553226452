export default {
    data() {
        return {
            story: {},
            preview: false,
            loading: false,
            lang: globalThis.$i18n.locale,
            version: null
        };
    },
    created: function () {
        this.preview = this.$route.query._storyblok_version === '';
        if (this.$storyblok) {
            this.$storyblok.on('change', () => {
                this.loadStory('draft');
            });
            this.$storyblok.on('published', () => {
                this.loadStory('published');
            });
            this.$storyblok.on('input', event => {
                if (event.story.content._uid === this.story.content._uid) {
                    this.story = event.story;
                }
            });
            this.$storyblok.pingEditor(() => {
                this.loadStory(this.$storyblok.inEditor || this.preview ? 'draft' : 'published');
            });
            // default load for storyblok
            if (this.$storyblok.inEditor)
                this.loadStory('draft');
        }
    },
    watch: {
        '$i18n.locale': {
            // TodO: Check if this works in vue3 compat mode
            immediate: true,
            handler(value) {
                if (value !== this.lang) {
                    this.loadStory();
                    this.lang = value;
                }
            },
            deep: true
        }
    },
    methods: {
        loadStory(version) {
            if (version)
                this.version = version;
            this.loading = true;
            const prefix = globalThis.$i18n.locale !== 'nl' && globalThis.$i18n.locale;
            const slug = prefix ? `${prefix}/${this.slug}` : this.slug;
            this.$storyblok.get({
                slug,
                version: this.version
            }, data => {
                this.story = {
                    content: {}
                };
                this.$nextTick(() => {
                    if (data.story.content && data.story.content.body)
                        data.story.content.body = this.findDeep(data.story.content.body);
                    this.story = data.story;
                    this.loading = false;
                });
            }, error => {
                this.loading = false;
            });
        },
        findDeep(data) {
            if (Array.isArray(data)) {
                data.forEach(el => this.findDeep(el));
            }
            else {
                return Object.keys(data).some((key) => {
                    if (data[key] === 'asset') {
                        data.filename = this.transformAssetUrl(data.filename, 'filters:format(webp)');
                    }
                    else if (data[key] && typeof data[key] === 'object') {
                        this.findDeep(data[key]);
                    }
                });
            }
            return data;
        },
        transformAssetUrl(image, option) {
            if (!image)
                return '';
            if (!option)
                return '';
            const imageService = 'https://img2.storyblok.com/';
            const path = image.replace('https://a.storyblok.com', '');
            return imageService + option + path;
        }
    }
};
