import { defineComponent, onMounted } from '@vue/composition-api';
import storyblok from '@/mixins/storyblok';
import Loader from '@/components/Loader.vue';
import { useMeta } from '@/shared/composables/useMeta';
import { SITE_URL } from '@/App.vue';
export default defineComponent({
    components: {
        Loader
    },
    mixins: [storyblok],
    setup(_, context) {
        const { root } = context;
        onMounted(() => {
            useMeta({
                title: root.$i18n.t('meta.about_us.title_tag').toString(),
                description: root.$i18n.t('meta.about_us.description_tag').toString(),
                url: `${SITE_URL}/about-us`,
                noIndex: false
            });
        });
        return { slug: 'about-us' };
    }
});
